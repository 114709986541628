import { Box, Button, Stack, styled, Typography } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUserAuth } from "../../redux-store/user/slice";

const StyledContainer = styled(Box)(() => ({
  backgroundImage: "url(/static/images/1.jpg)",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  height: "100vh",
  display: "flex",
  alignItems: "center",
}));

const SignInPage = () => {
  const dispatch = useDispatch();
  const naviagte = useNavigate();

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      dispatch(
        setUserAuth({ isSignedIn: true, token: tokenResponse.access_token })
      );
      naviagte("/dashboard");
    },
    scope: "https://www.googleapis.com/auth/contacts.readonly",
  });

  return (
    <StyledContainer>
      <Stack gap={2} width={{ xs: "50%", xl: "30%" }} ml={10}>
        <Typography variant="h3">Say hello to the world</Typography>
        <Typography variant="body1">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
          lobortis maximus nunc, ac rhoncus odio congue quis. Sed ac semper
          orci, eu porttitor lacus.
        </Typography>
        <Button onClick={login} variant="contained">
          Sign In With Google
        </Button>
      </Stack>
    </StyledContainer>
  );
};

export default SignInPage;
