import { persistReducer } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import settingsSlice from "./settings/slice";
import contactsSlice from "./contacts/slice";
import userSlice from "./user/slice";

const persistConfig = {
  key: "chat",
  storage,
  whitelist: ["settings", "user"],
};

const rootReducer = combineReducers({
  settings: settingsSlice,
  contacts: contactsSlice,
  user: userSlice,
});

export default persistReducer(persistConfig, rootReducer);
