import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  theme: "dark",
};

export const settingsSlice = createSlice({
  name: "slice/settings",
  initialState,
  reducers: {
    changeTheme: (state) => {
      state.theme = state.theme === "dark" ? "light" : "dark";
    },
  },
});

export const { changeTheme } = settingsSlice.actions;

export default settingsSlice.reducer;
