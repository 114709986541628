import { Box, Divider } from "@mui/material";
import AppHeader from "./AppHeader";

const AppLayout = ({ children }) => {
  return (
    <Box sx={{ maxHeight: "100vh", overflow: "hidden" }}>
      <AppHeader />
      <Divider />
      {children}
    </Box>
  );
};

export default AppLayout;
