import { typographyClasses } from "@mui/material/Typography";
import { accordionClasses } from "@mui/material/Accordion";
import { accordionSummaryClasses } from "@mui/material/AccordionSummary";

export default function accordion(theme) {
  return {
    MuiAccordion: {
      styleOverrides: {
        root: {
          padding: 0,
          backgroundColor: "transparent",
          "&::before": {
            height: 0,
          },
          [`&.${accordionClasses.expanded}`]: {
            backgroundColor: "transparent",
            margin: 0,
          },
          [`&.${accordionClasses.disabled}`]: {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: "max-content",
          padding: 0,
          margin: 0,
          "& .Mui-expanded": {
            marginTop: 0,
            marginBottom: 0,
          },
          "& .MuiAccordionSummary-content": {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
          },
          [`&.${accordionSummaryClasses.disabled}`]: {
            opacity: 1,
            color: theme.palette.action.disabled,
            [`& .${typographyClasses.root}`]: {
              color: "inherit",
            },
          },
          [`&.${accordionSummaryClasses.expanded}`]: {
            minHeight: "max-content",
            margin: 0,
            [`& .${typographyClasses.root}`]: {
              color: theme.palette.primary.main,
            },
            "& .MuiAccordionSummary-expandIconWrapper": {
              color: theme.palette.primary.main,
            },
          },
        },
        expandIconWrapper: {
          color: "inherit",
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
  };
}
