import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import typography from "./typography";
import customShadows from "./custom-shadows";
import componentsOverrides from "./overrides";
import palette from "./palette";
import { useSelector } from "react-redux";

const MuiThemeProvider = ({ children }) => {
  const selectedTheme = "dark";
  const theme = createTheme({
    palette: palette(selectedTheme),
    shadows: Array(25).fill("none"),
    typography: typography,
    customShadows: customShadows(selectedTheme),
  });
  theme.components = componentsOverrides(theme);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default MuiThemeProvider;
