import { Box, Grid2, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMyContactsAsync } from "../../redux-store/contacts/slice";
import AppLayout from "../../components/layout";
import ContactsList from "../../components/dashboard/ContactsList";
import Scrollbar from "../../components/scrollbar";

const DashboardPage = () => {
  const dispatch = useDispatch();

  const myContacts = useSelector((state) => state.contacts.list);
  const auth = useSelector((state) => state.user.auth);

  useEffect(() => {
    if (!myContacts.length && auth.access_token) {
      dispatch(getMyContactsAsync(auth.access_token));
    }
  }, [auth.access_token]);

  return (
    <AppLayout>
      <Box>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}>
            <Scrollbar sx={{ height: "calc(100vh - 5em)" }}>
              <Box pr={2}>
                <Box border="1px solid" borderColor="divider">
                  {myContacts?.length ? (
                    <ContactsList />
                  ) : (
                    <Typography
                      variant="body1"
                      p={2}
                      color="text.secondary"
                      sx={{ height: "calc(100vh - 6em)" }}
                    >
                      You dont have available contacts
                    </Typography>
                  )}
                </Box>
              </Box>
            </Scrollbar>
          </Grid2>
          <Grid2 size={10}>
            <Box
              component="iframe"
              src="https://gemma.askai.bio/"
              width="100%"
              height="100%"
              border={0}
            />
          </Grid2>
        </Grid2>
      </Box>
    </AppLayout>
  );
};

export default DashboardPage;
