import { Box } from "@mui/material";

const SignUpPage = () => {
  return (
    <Box>
      <Box>sign up</Box>
    </Box>
  );
};

export default SignUpPage;
