import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/Image";
import { useSelector } from "react-redux";
import { Box, Divider, ListItemButton, Typography } from "@mui/material";

const ContactsList = () => {
  const myContacts = useSelector((state) => state.contacts.list);
  return (
    <Box>
      <Box pl={1} pt={2}>
        <Typography variant="h6">My Contacts</Typography>
      </Box>
      <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
        {myContacts.map((contact) => {
          const name =
            contact?.names && contact?.names[0]
              ? contact.names[0].displayName
              : "";
          const phone =
            contact?.phoneNumbers && contact?.phoneNumbers[0]
              ? contact.phoneNumbers[0].canonicalForm
              : "";
          const photo =
            contact?.photos && contact?.photos[0] ? contact.photos[0].url : "";
          return (
            <Box key={contact.resourceName}>
              <ListItemButton sx={{ padding: 0 }}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar src={photo}>
                      <ImageIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={name} secondary={phone} />
                </ListItem>
              </ListItemButton>
              <Divider />
            </Box>
          );
        })}
      </List>
    </Box>
  );
};

export default ContactsList;
