import peopleAPI from "../_api";

export const getMyContacts = async (token) => {
  try {
    // Fetch contacts using Google People API
    const contactsResponse = await peopleAPI.get("/people/me/connections", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        personFields: "names,emailAddresses,phoneNumbers,photos",
        pageSize: 200, // Adjust the number of contacts to fetch
      },
    });
    return contactsResponse.data;
  } catch (error) {
    console.error("Error fetching contacts:", error);
  }
};
