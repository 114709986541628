import "simplebar/dist/simplebar.min.css";
import { forwardRef } from "react";
import SimpleBar from "simplebar-react";
import { styled } from "@mui/material/styles";

const ScrollbarRoot = styled(SimpleBar)(({ theme }) => ({
  "& .simplebar-scrollbar::before": {
    backgroundColor: theme.palette.primary.main,
    opacity: 1,
    width: 8,
  },
}));

const Scrollbar = forwardRef((props, ref) => {
  return <ScrollbarRoot ref={ref} {...props} />;
});

export default Scrollbar;
