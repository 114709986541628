import { alpha } from "@mui/material/styles";

const GREY = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  900: "#161C24",
};

const PRIMARY = {
  lighter: "#31ca8f",
  light: "#1a3031",
  modeLight: "#407c80",
  main: "#31ca8f",
  dark: "#269e70",
  darker: "#620027",
  contrastText: "#FFFFFF",
};

const SECONDARY = {
  lighter: "#31ca8f",
  light: "#d80056",
  main: "#31ca8f",
  dark: "#269e70",
  darker: "#620027",
  contrastText: "#FFFFFF",
};

const INFO = {
  lighter: "#CAFDF5",
  light: "#61F3F3",
  main: "#00B8D9",
  dark: "#006C9C",
  darker: "#003768",
  contrastText: "#FFFFFF",
};

const SUCCESS = {
  lighter: "#D3FCD2",
  light: "#77ED8B",
  main: "#2ebd85",
  dark: "#118D57",
  darker: "#065E49",
  contrastText: "#ffffff",
};

const WARNING = {
  lighter: "#FFF5CC",
  light: "#FFD666",
  main: "#FFAB00",
  dark: "#B76E00",
  darker: "#7A4100",
  contrastText: GREY[800],
};

const ERROR = {
  lighter: "#FFE9D5",
  light: "#FFAC82",
  main: "#bb1956",
  dark: "#B71D18",
  darker: "#7A0916",
  contrastText: "#FFFFFF",
};

const COMMON = {
  common: {
    black: "#000000",
    white: "#FFFFFF",
  },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: alpha(GREY[500], 0.1),
  action: {
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const neutral = {
  main: "rgba(86, 106, 127, 0.1)",
  100: "rgba(60, 81, 134, 0.5)",
  200: "#E5E7EB",
  300: "#D1D5DB",
  400: "#9CA3AF",
  500: "#6B7280",
  600: "#4B5563",
  700: "#374151",
  800: "#1F2937",
  900: "#111827",
};

export default function palette(mode) {
  const light = {
    ...COMMON,
    mode: "light",
    neutral,
    text: {
      primary: GREY[800],
      secondary: GREY[600],
      disabled: GREY[500],
      contrast: "#31ca8f",
    },
    background: {
      paper: "#FFFFFF",
      default: "#FFFFFF",
      neutral: GREY[200],
      input: "rgba(0, 0, 0, .03)",
      avatar: "#1d1f28",
      selected: "#1e2b2a",
      cartoffertitle: "#1e242b",
      cartoffertitleopen: "#1e242b",
    },
    action: {
      ...COMMON.action,
      active: GREY[600],
    },
  };

  const dark = {
    ...COMMON,
    mode: "dark",
    text: {
      primary: "#FFFFFF",
      secondary: GREY[500],
      disabled: GREY[600],
      contrast: "#31ca8f",
    },
    background: {
      paper: "#1d1f28",
      default: "#1d1f28",
      neutral: GREY[200],
      input: "#2c2e3f",
      avatar: "#1d1f28",
      selected: "#1e2b2a",
      cartoffertitle: "#1e242b",
      cartoffertitleopen: "#20272e",
    },
    action: {
      ...COMMON.action,
      active: GREY[500],
    },
  };

  return mode === "light" ? light : dark;
}
