export default function tabs(theme) {
  return {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: theme.palette.text.primary,
        },
        scrollButtons: {
          width: 48,
          borderRadius: "50%",
        },
        root: {
          minHeight: "max-content",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          paddingRight: theme.spacing(1.7),
          paddingLeft: theme.spacing(1.7),
          paddingTop: theme.spacing(0.8),
          paddingBottom: theme.spacing(0.8),
          marginRight: theme.spacing(0.4),
          marginLeft: theme.spacing(0.4),
          opacity: 1,
          minWidth: "max-content",
          minHeight: "max-content",
          fontWeight: theme.typography.fontWeightMedium,
          "&:hover": {
            background: theme.palette.mode === "light" ? theme.palette.primary.main : theme.palette.background.input,
            borderRadius: "25px",
            color: theme.palette.common.white,
          },
          "&.Mui-selected": {
            background: theme.palette.mode === "light" ? theme.palette.primary.main : theme.palette.background.input,
            color: theme.palette.common.white,
            borderRadius: "15px",
          },
        },
      },
    },
  };
}
