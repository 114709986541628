import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import MuiThemeProvider from "./theme";
// @redux integration utils
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux-store/store";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="310590011621-9srvm5c3s7ptm5ct242t6vkfhohlajk9.apps.googleusercontent.com">
      <BrowserRouter>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <MuiThemeProvider>
              <App />
            </MuiThemeProvider>
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </GoogleOAuthProvider>
  </React.StrictMode>
);
