import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  auth: {
    signedIn: false,
    access_token: null,
  },
};

export const userSlice = createSlice({
  name: "slice/contacts",
  initialState,
  reducers: {
    setUserAuth: (state, { payload }) => {
      localStorage.setItem("_access_token", payload.token);
      state.auth.signedIn = payload.isSignedIn;
      state.auth.access_token = payload.token;
    },
    logOut: (state, { payload }) => {
      localStorage.removeItem("_access_token");
      state.auth.signedIn = false;
    },
  },
});

export const { setUserAuth, logOut } = userSlice.actions;

export default userSlice.reducer;
