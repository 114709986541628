import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
// import HomePage from "./pages/Home";
import SignInPage from "./pages/auth/sign-in";
import SignUpPage from "./pages/auth/sign-up";
import DashboardPage from "./pages/dashboard";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.user.auth);

  useEffect(() => {
    if (!auth.signedIn) {
      navigate("/");
    }
  }, [location.pathname, auth.signedIn]);

  return (
    <Routes>
      <Route path="/" element={<SignInPage />} />
      <Route path="/sign-in" element={<SignInPage />} />
      <Route path="/sign-up" element={<SignUpPage />} />
      <Route path="/dashboard" element={<DashboardPage />} />
    </Routes>
  );
};

export default App;
