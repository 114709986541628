import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getMyContacts } from "./_requests";

const initialState = {
  list: [],
  isContactsLoading: false,
};

export const getMyContactsAsync = createAsyncThunk(
  "contacts/getMyContacts",
  getMyContacts
);

export const contactsSlice = createSlice({
  name: "slice/contacts",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getMyContactsAsync.pending, (state) => {
        state.isContactsLoading = true;
      })
      .addCase(getMyContactsAsync.fulfilled, (state, { payload }) => {
        state.isContactsLoading = false;
        state.list = payload?.connections || [];
      })
      .addCase(getMyContactsAsync.rejected, (state) => {
        state.isContactsLoading = false;
      });
  },
});

export default contactsSlice.reducer;
